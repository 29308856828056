.footer {
  padding: 2rem 17.5rem;
  display: flex;
  justify-content: center;
  color: #202020;
  font-family: Raleway;
  font-weight: 400;
  font-size: 1.8rem;
  margin: 0 30rem;
  border-top: 0.5px solid #616060;
}
@media (max-width: 640px) {
  .footer {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    margin: 0 8rem;
    border-top: 0.5px solid #616060;
  }
}
