body {
  margin: 0;
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #4b4b4b;
}

a:hover {
  text-decoration: underline;
}
html {
  font-size: 62.5%;
}

@media (max-width: 640px) {
  html,
  body {
    font-size: 40%;
    width: 100%;
    overflow-x: hidden;
  }
}
